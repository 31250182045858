import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'

import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {langDefault} from './i18n_lib'
import {supportedLangs_} from './i18n_status'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

export * from 'i18next'

//console.debug(supportedLangs_.map(lng => lng.lang));

i18next
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpApi)

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)

    // connect with React
    .use(initReactI18next)

    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //debug: true,

        //lng: 'en',
        fallbackLng: langDefault,
        //supportedLngs: ['en', 'ru'],
        supportedLngs: supportedLangs_.map((lng) => lng.lang),

        defaultNS: 'translation',
        ns: 'translation',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        react: {
            useSuspense: false,
        },

        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    })

//export const i18n;

//export default i18n;
