//
// Translation of frequently used words
//

import i18n from 'i18next'

class FreqWord {
    text: string
    t: string // Translated

    constructor(text: string) {
        this.text = text
        this.t = text
    }
}

const words = ['Open', 'Details', 'POSITIVE', 'NEGATIVE', 'SUSPICUOUS']
const wordsMap = new Map<string, FreqWord>()

class FreqWords {
    open: FreqWord
    details: FreqWord
    positive: FreqWord
    negative: FreqWord
    suspicious: FreqWord

    constructor(map: Map<string, FreqWord>) {
        this.open = map.get('Open')!
        this.details = map.get('Details')!

        this.positive = map.get('POSITIVE')!
        this.negative = map.get('NEGATIVE')!
        this.suspicious = map.get('SUSPICIOUS')!
    }
}

export const i18nFreq_ = i18nFreqInit()

export function i18nFreqInit(): FreqWords {
    for (let word of words) {
        wordsMap.set(word, new FreqWord(word))
    }

    return new FreqWords(wordsMap)
}

export function i18nFreqLangUpdate() {
    for (let [word, wi] of wordsMap) {
        wi.t = i18n.t(word)
        //console.debug(wi.t);
    }
}

//export default
