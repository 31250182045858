import {supportedLangs_} from './i18n_status'

export const langDefault = 'en'

export function langSanitize(lang: string | undefined | null, fallback?: string) {
    if (!lang || lang.length === 0) {
        if (fallback) return langSanitize(fallback)
        return langDefault
    }

    for (let sl of supportedLangs_) {
        if (sl.lang === lang) return lang
    }

    if (fallback) return langSanitize(fallback)
    return langDefault
}
