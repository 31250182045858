import React from 'react'

import { Box } from '@mui/material'

import { Typography } from '@mui/material'
import { Card, CardContent }  from '@mui/material'
import { Link } from '@mui/material';

import Env from 'env'


import PageContents from 'components/PageContents'

const boxMinWidth = Env.isMobile ? 1 : 600
const boxWidth = Env.isMobile ? 1 : 0.5

export default function ToolsPage() {
    return <PageContents title='Tools'>
        <Typography variant='h1' color='#eeeeee' align='center'>Tools</Typography>

        <Link href='/tools/rcbc'>
            <Box justifyContent='center' sx={{
                minWidth: boxMinWidth,
                width: boxWidth,
                mx: 'auto',
                mt: 4,
                borderColor: '#eeeeee',
                borderWidth: 1,
                borderStyle: 'solid',
            }}>
                <Typography 
                    variant='h2' 
                    color='#eeeeee'
                    sx={{
                        m: 4,
                    }}>Residual Cancer Burden Calculator</Typography>
            </Box>
        </Link>

    </PageContents>
}
